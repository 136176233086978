import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Payment.scss';
import axios from 'axios';
import * as acTypes from '../../store/actions';
import { connect } from 'react-redux';
import Checkout from './../../components/Checkout/Checkout';
import FooterLight from './../../components/FooterLight/FooterLight';
import HeaderLight from './../../components/HeaderLight/HeaderLight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Connector from 'activecampaign_v3';
import PaymentSuccess from './../../components/PaymentSuccess/PaymentSuccess';
import { translate } from 'react-i18next';
import compose from 'compose-function';



const actId = "224635093";
const eventKey = "df158451e05eb31744a99d59251513283a91b9cc";
const apiKey = "";
const apiUrl = "";
const ActiveCamp = new Connector(actId, eventKey, apiKey, apiUrl);


class Payment extends Component {
	constructor(props) {
		super(props);
	}

	state = {
		basketData: null,
		discount: false,
		paymentSuccess: false,
		errorMessage: "",
		couponCode: "",
		appliedCouponCode: "",
		openCouponDialog: false,
		couponDiscountApplied: false,
		openAlert: false,
		couponInvalid: false,
		email: "",
		name: "",
		submitDisable:false,
		couponDiscountPercent: parseFloat(0)
	}


	handleCloseAlert = () => {
		this.setState({ errorMessage: "", openAlert: false });
	}

	handleCloseCouponDialog = () => {
		this.setState({ couponCode: "", openCouponDialog: false });
	}
	handleInvalidCoupon = () => {
		this.setState({ couponInvalid: true ,couponCodeError: "Invalid coupon code." });
	}

	handleOpenCouponDialog = () => {
		this.setState({ couponInvalid: false,openCouponDialog: true });
	}


	handleFreeBasket = () => {

		console.log("handleFreeBasket", this.props);

		const paymentData = {
			token: "x",
			guid: this.props.basketId,
			discount: true
		};

		const headers = {
			'Authorization': 'Bearer ' + this.props.token
		}

		var that = this;
		axios.post('/Order/payment', paymentData, {
			headers: headers
		})
			.then(function (response) {

				that.setState({ paymentSuccess: true }, that.handleQueryString);


			}).catch(function (error) {
				// handle error
				that.setState({ errorMessage: error.response.data, openAlert: true });

			});

	}
	handleDisable()
	{
	  this.setState({ submitDisable: true });
	}

	handleResult = (result) => {


		if (result.error) {
			// Show error to your customer.
			console.log(result.error.message);

			this.setState({ errorMessage: result.error.message, openAlert: true });


		} else {

			console.log("stR", result.token.id);
			console.log("utk", this.props.token);
			console.log("bid", this.props.basketId);


			const paymentData = {
				token: result.token.id,
				guid: this.props.basketId,
				discount: this.state.discount
			};

			const headers = {
				'Authorization': 'Bearer ' + this.props.token
			}
			var that = this;
			axios.post('/Order/payment', paymentData, {
				headers: headers
			})
				.then(function (response) {

					that.setState({ paymentSuccess: true }, that.handleQueryString);


				}).catch(function (error) {
					// handle error
					that.setState({ errorMessage: error.response.data, openAlert: true });

				});

		}
	}

	handleQueryString = () => {
		const query = new URLSearchParams(this.props.location.search);
		const paymentSuccess = query.get("paymentSuccess");
		console.log("paymentSuccess");
		console.log(paymentSuccess);
		if(paymentSuccess === null)
		{
			var url = this.props.location.pathname;
			if(this.props.location.search){
				url += this.props.location.search +"&paymentSuccess=true";
			}else{
				url += "?paymentSuccess=true";
			}
			this.props.history.replace(url);
			setTimeout(() => window.location.reload(false), 500);
		}
	}

	handleDiscount() {
		this.setState({ "discount": true });
	}

	onChangeCouponCode(event) {
		this.setState({ couponCode: event.target.value ,couponInvalid: false ,couponCodeError: "" });
	}

	handleCouponCode() {

		console.log("handleCouponCode");
		console.log(this.state);
		const valid = this.state.couponCode ? true : false;

		this.setState({ couponCodeError: valid ? "" : "Please fill in a coupon code." });

		if (valid) {

			const couponData = {};
			const headers = {
				'Authorization': 'Bearer ' + this.props.token
			}
			var url = '/Order/usecouponCode/' + this.props.match.params.basketId + '/' + this.state.couponCode;
			var that = this;
			axios.post(url, couponData, {
				headers: headers
			})
				.then(function (response) {
					console.log(response);
					var discountPercent =  response.data.percentOff;
					that.setState({ couponDiscountPercent: parseFloat(discountPercent), couponDiscountApplied: true, appliedCouponCode: that.state.couponCode }, that.handleCloseCouponDialog);
				}).catch(function (error) {
					console.log("CouponCodeErrorResponse", error);
					that.handleInvalidCoupon();
					//that.handleCloseCouponDialog();
				});
		}
	}

	handleCouponCancel() {

		const couponData = {};
		const headers = {
			'Authorization': 'Bearer ' + this.props.token
		}
		var url = '/Order/usecouponCode/' + this.props.basketId + '/' + "0";
		var that = this;
		axios.post(url, couponData, {
			headers: headers
		})
			.then(function (response) {
				var discountPercent = 10;

				that.setState({ couponDiscountPercent: parseFloat(0), couponDiscountApplied: false, appliedCouponCode: "" });


			});
	}

	autoCouponCode(){

		console.log("autoCouponCode");
		//console.log(this.state);
		this.handleCouponCode();

	}

	componentDidMount() {

		var token = null;
		var basketId = null;
		const query = new URLSearchParams(this.props.location.search);
		const couponCode = query.get("couponCode");
		console.log("couponCode" + couponCode);	
		if(couponCode != null)
		{
			this.setState({ couponCode:couponCode },this.autoCouponCode);

		}
		else 
		{
			this.props.onCouponCode("");
		}

		console.log(this.props.history.length);
		console.log("BID", this.props.basketId);
		console.log(this.props);

		if (!this.props.basketId || !this.props.token) {
			console.log("sd");
			console.log(this.props.match.params.basketId);
			axios.get('/Users/authenticate/' + this.props.match.params.basketId).then(response => {
				setTimeout(() => {
					new Connector(this.props.t("ActiveCamiaignActid"), this.props.t("ActiveCamiaignActKey"), apiKey, apiUrl).trackSite("", { url: encodeURIComponent(window.location.href), referrer: encodeURIComponent(window.location.href) }, response.data.email);
					}, 5000);
				//this.props.onUserName()
				//this.props.onUserEmail()
				this.props.onUserToken(response.data.token);
				this.props.onBasketId(this.props.match.params.basketId);
				this.props.onFullName(response.data.name);
				this.props.onEmail(response.data.email);
				this.setState({ email: response.data.email, name: response.data.name });
				this.getBasket(response.data.token, this.props.match.params.basketId);
			});
		} else {
			this.setState({ email: this.props.email, name: this.props.name });
			this.getBasket(this.props.token, this.props.match.params.basketId);
			setTimeout(() => {
				new Connector(this.props.t("ActiveCamiaignActid"), this.props.t("ActiveCamiaignActKey"), apiKey, apiUrl).trackSite("", { url: encodeURIComponent(window.location.href), referrer: encodeURIComponent(window.location.href) }, this.props.email);
				}, 5000);
		}



	}

	getBasket(token, basketId) {
		const headers = {
			'Authorization': 'Bearer ' + token
		}

		axios.get('Order/getUserBasket/' + basketId, {
			headers: headers
		}).then(response => {
			console.log("Order/getUserBasket/");
			console.log(response.data);
			var paymentSuccess = (response.data.status === 10 || response.data.status === 3);
			this.setState({ basketData: response.data, paymentSuccess : paymentSuccess  });
			if(paymentSuccess)
			{
				this.handleQueryString();
			}
		});
	}

	goBack() {
		console.log("go BACK");
		this.props.history.goBack();
	}

	render() {
		const { t } = this.props;
		return (
			<div className="Payment">
				<div className="d-flex flex-column" style={{ minHeight: "100%" }}>
					<div className="flex-shrink-0 flex-grow-0">
						<div className={this.state.paymentSuccess ? "mobile-header-success" : ""} >
							<HeaderLight />
						</div>
					</div>
					<div className="flex-shrink-0 flex-grow-1">
						{!this.state.paymentSuccess && this.state.basketData ?
							<Checkout {...this.state.basketData}
								name={this.state.name}
								email={this.state.email}
								submitDisable={this.state.submitDisable}
								handleFreeBasket={this.handleFreeBasket}
								handleResult={this.handleResult}
								handleDiscount={() => this.handleDiscount()}
								handleDisable={() => this.handleDisable()}
								handleCouponDialog={() => this.handleOpenCouponDialog()}
								handleCouponCancel={() => this.handleCouponCancel()}
								couponDiscountApplied={this.state.couponDiscountApplied}
								couponCode={this.state.appliedCouponCode}
								couponDiscountPercent={this.state.couponDiscountPercent}
								couponInvalid = {this.state.couponInvalid}
								onBack={() => this.goBack()}
							/> : null}
						{this.state.paymentSuccess ? <PaymentSuccess basketId={this.props.basketId} /> : null}
					</div>
					<div className="flex-shrink-0 flex-grow-0" >
						<div className={this.state.paymentSuccess ? "" : "mobile-hidden-footer"} >
							<FooterLight />
						</div>
					</div>
				</div>
				<Dialog
					open={this.state.openAlert}
					onClose={this.handleCloseAlert}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{this.state.errorMessage}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleCloseAlert} color="primary" autoFocus>
							{t("OK")}
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.openCouponDialog}
					onClose={this.handleCloseCouponDialog}
					className="coupon-dialog"
				>
					<div className="coupon-dialog-content">
						<p className="coupon-title">  {t("Coupon code")} </p>
						<input
							name="name"
							type="text"
							placeholder="Enter a coupon code"
							className={this.state.couponInvalid ? "code-input invalid":"code-input"}
							required
							onChange={this.onChangeCouponCode.bind(this)}
						/>
						<p className="not-valid-error">{this.state.couponCodeError}</p>
						<div className="d-flex justify-content-between align-items-center ">
							<button className="btn-o-d" onClick={this.handleCloseCouponDialog} type="button">{t("Cancel")}</button>
							<button className="btn-d" type="button" onClick={() => this.handleCouponCode()} >{t("Apply")}</button>
						</div>
					</div>
				</Dialog>

			</div>
		)
	};
}

Payment.defaultProps = {};

const mapStateToProps = state => {
	return {
		token: state.usr.token,
		lang: state.usr.lang,
		basketId: state.usr.basketId,
		name: state.usr.fullName,
		email: state.usr.email,
		couponCode: state.usr.couponCode
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onUserToken: (token) => dispatch({ type: acTypes.SET_TOKEN, token: token }),
		onBasketId: (basketId) => dispatch({ type: acTypes.SET_BASKET_ID, basketId: basketId }),
		onEmail: (email) => dispatch({ type: acTypes.SET_EMAIL, email: email }),
		onFullName: (fullName) => dispatch({ type: acTypes.SET_FULLNAME, fullName: fullName }),
		onCouponCode: (couponCode) => dispatch({ type: acTypes.SET_COUPON_CODE, couponCode: couponCode }),
	}
};

export default compose(translate("translations"), connect(mapStateToProps, mapDispatchToProps))(Payment);
