import React from 'react';
import PropTypes from 'prop-types';
import './Checkout.scss';
import TextField from '@material-ui/core/TextField';
import arrow from './../../assets/icons/arrow.svg';
import external from './../../assets/icons/external-link.svg';
import calendar from './../../assets/icons/calendar.svg';
import tree from './../../assets/images/tree.png';
import amex from './../../assets/images/amex.png';
import visa from './../../assets/images/visa.png';
import mastercard from './../../assets/images/mastercard.png';
import { firstName } from './../../assets/Helpers.js'
import moment from 'moment'
import CheckoutForm from './CheckoutForm/CheckoutForm';
import { translate } from 'react-i18next';
import {
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import { AIRBNB_FONT_FULL_PATH } from './../../index.js';


//const stripePromise = loadStripe('pk_test_51Ho3IxJPD2yyfWSCoPx27jgT5TMCyB7ZMPqYWz8o8IpVQGUeOfjLFy9jOcqTmWLrwzcMZMfM34qmQt8Q6INjLGoH00dDiH7I45');

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stripe: null, discount: false, formValid: false};
  }

  componentDidMount() {
    //console.log(`airbnb font path is: ${ AIRBNB_FONT_FULL_PATH }`);

    const stripeKey = "pk_test_51Ho3IxJPD2yyfWSCoPx27jgT5TMCyB7ZMPqYWz8o8IpVQGUeOfjLFy9jOcqTmWLrwzcMZMfM34qmQt8Q6INjLGoH00dDiH7I45";
    //const stripeKey = "pk_live_51Ho3IxJPD2yyfWSCYs7XofdhjNSMFaglKR7yXzJYjjPNmvb7WbYthSSBSNqT8etEo4NyhCNyqK5alz07Y9B77qBL00pRnEmeTt";

    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeKey) });
    } else {
      var el = document.querySelector('#stripe-js');
      if (el) {
        el.addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({ stripe: window.Stripe(stripeKey) });
        });
      } else console.log("NO EL");
    }
  }

  applyDiscount = (e) => {
    this.setState({ discount: true });
    this.props.handleDiscount();
  };

  cancelCouponDiscount = (e) => {
    //this.props.cancelCouponDiscount();
  };

  handleCouponPopup = (e) => {

    this.props.handleCouponDialog();
  };

  handleCouponCancel  = (e) => {
    console.log("handleCouponCancel1");
     this.props.handleCouponCancel();
  };

  isFreeBasket(){

    var totalMultiplier = this.state.discount || this.props.couponDiscountApplied ? parseFloat(this.props.payment.discountedPrice) : parseFloat(this.props.payment.price);

    return totalMultiplier == 0 ? true : false;

  }

  calculateDiscountedPrice(){

    let multiplier = parseFloat(this.props.basketResult.length);

    var priceStart = this.state.discount ?  parseFloat(this.props.payment.discountedPrice) : parseFloat(this.props.payment.price);

    var lastPrice = priceStart - (priceStart / 100 * parseFloat(this.props.couponDiscountPercent));

    return parseFloat(lastPrice * multiplier).toFixed(2);

  }

  calcDiscountPrice(){
    return (parseFloat( parseFloat(this.props.payment.price) - this.calculateDiscountedPrice()) * parseFloat(this.props.basketResult.length) ).toFixed(2);
  }

  getCurrency(){
    return(this.props.basketSource[0] && this.props.basketSource[0].priceCurrency ? this.props.basketSource[0].priceCurrency:"");
  }

  handleChange(formValid) {
    if (formValid.cardNumber && formValid.cardCvc && formValid.cardExpiry && formValid.cardHolder) {
      this.setState({ formValid: true });
    }
    else {
      this.setState({ formValid: false });
    }
  }


  render() {
    const { t } = this.props;

    const elFonts = [
      {
        src: "url(" + AIRBNB_FONT_FULL_PATH + ")",
        cssSrc: AIRBNB_FONT_FULL_PATH,
        family: 'Airbnb Cereal App',
        style: 'normal',
      }
    ];
    return (
      <div className="Checkout">
        <div className="container">

        </div>
        <div className="container">
          <div className="d-flex flex-wrap pay-content">
            <div className="left">
              
              <div className="coupon-container-mobile">

              { !this.props.couponDiscountApplied ? 
                <div className="coupon-code">
                  <button className="btn enter-coupon" onClick={this.handleCouponPopup} >{this.props.t("Enter a coupon code")}</button>
                </div>
              :null}
                { parseFloat(this.props.payment.discount) > 0 ?
                <div className={this.state.discount ? 
                    "applied coupon-cont d-flex flex-wrap justify-content-between align-items-center" 
                  : "coupon-cont d-flex flex-wrap justify-content-between align-items-center"}
                >
                  <p>
                  {/*Get 2 plans, 4th %50 discounted!*/}
                  {this.props.t("Get discount!",{discount: t("CurencyPrice",{currency: this.getCurrency(), price :parseFloat(this.props.payment.discount)*parseFloat(this.props.basketResult.length)})})}
                  </p>

                  {!this.state.discount ? <button className="btn btn-apply" onClick={this.applyDiscount}>{this.props.t("Apply")}</button> :null}
                  {this.state.discount ? <p className="applied-text">{this.props.t("Discount applied")}</p> :null}
                </div>
                :null}
                { this.props.couponDiscountApplied ? 
                      <div className="applied coupon-cont d-flex justify-content-between align-items-center">
                          <p className="coupon-code-txt truncate">{this.props.couponCode}</p> 
                           <div className="flex-shrink-0 coupon-code-content-right">
                            <div><p className="applied-text">{this.props.t("DiscountPercent",{couponDiscountPercent :parseFloat( this.props.couponDiscountPercent ).toFixed(0)})}</p></div>
                            <div className="coupon-code-cancel"><button className="btn btn-coupon-cancel" onClick={this.handleCouponCancel} >{this.props.t("Cancel")}</button></div>
                          </div>
                      </div>
                    :null}
              </div>
              

              <div className="form-wrap">
                {!this.isFreeBasket() ?
                  <div className="pay-w d-flex justify-content-between align-items-center">
                    <h4>{this.props.t("Pay with")}</h4>
                    <div className="cards d-flex align-items-center">
                      <img src={visa} />
                      <img src={mastercard} />
                      <img src={amex} />
                    </div>
                  </div>
                  : null}
                {!this.isFreeBasket() && this.state.stripe ?
                  <div className="stripe-container" >
                    <StripeProvider stripe={this.state.stripe}>
                      <Elements options={{ fonts: elFonts }} >
                        <CheckoutForm handleResult={(res) => this.props.handleResult(res)} handleChange={(res) => this.handleChange(res)}  handleDisable={() => this.props.handleDisable()}/>
                      </Elements>
                    </StripeProvider>
                  </div>
                  : null}
                {!this.isFreeBasket() ?
                  <div className="terms-container">
                    <p className="terms" dangerouslySetInnerHTML={{ __html: t("TermsText", { name: this.props.name, email: this.props.email }) }} />
                  </div>
                  : null}
              </div>
              <h1>{t("Review and Pay")}</h1>
              <p className="info-txt">{t("After payment, you can leave the e-mail addresses of the people you want your trip plan to be shared with, or you can share it later if you wish.")}</p>
              <div className="cart">

                {this.props.basketSource.map((plan, i) =>
                  <div key={plan.id} className="product d-flex justify-content-between align-items-center flex-wrap">
                    <div className="product-details d-flex">
                      <div className="product-image flex-shrink-0 bgImg" style={{ backgroundImage: "url(" + plan.themeImages[0] + ")" }}>

                        {
                          this.props.basketResult.filter(item => item.planId == plan.id).map((obj, index) =>
                            <div className="date-wrap" key={index}>
                              <p className="month">{moment(obj.selectedDate).format('MMM')}</p>
                              <p className="day">{moment(obj.selectedDate).format('DD')}</p>
                            </div>
                          )}

                      </div>
                      <div className="product-info">
                        <p className="product-name truncate" >{plan.themeOfTheDay}</p>
                        <p className="product-designer truncate">{t("Designed by", { tridDesigner: firstName(plan.tridDesigner.fullName) })}</p>
                      </div>
                      
                      <div className="product-link">
                        <a href={`/${plan.languageString}/plan/${plan.uniqId}/${plan.planUrl}`} target="_blank"><img src={external} /></a>
                      </div>
                    </div>
                    {/*<button className="btn checkout-btn"> Change my Day </button>*/}
                  </div>
                )}

                  </div>
                  { this.props.basketResult.length > 2 ?
                    <div>
                    <p className="see-more">{t("See More")}</p>
                    </div>
                  :null}
                  <div className="planting d-flex justify-content-start align-items-center">
                    <img src={tree}/>
                    <p className="tree-txt" style={{paddingLeft:"15px"}}>
                      {parseInt(this.props.basketResult.length) > 1 ?
                        t("By purchasing multiple", { count: this.props.basketResult.length, city: t("City_" + this.props.basketSource[0].eventCity) })
                        :
                        t("By purchasing single", { count: this.props.basketResult.length, city: t("City_" + this.props.basketSource[0].eventCity) })
                      }
                    </p>
                  </div>
              </div>
              <div className="right">
              
              <div className="coupon-container">
               
                    <div className="coupon-code desktop-ccode">

                    { !this.props.couponDiscountApplied ?
                      <button className="btn enter-coupon" onClick={this.handleCouponPopup} >{this.props.t("Enter a coupon code")}</button>
                       :null}
                    </div>
               
                     { parseFloat(this.props.payment.discount) > 0 ?
                    <div className={this.state.discount ? 
                        "applied coupon-cont d-flex flex-wrap justify-content-between align-items-center" 
                      : "coupon-cont d-flex flex-wrap justify-content-between align-items-center"}
                    >
                      <p>
                      {/*Get 2 plans, 4th %50 discounted!*/}
                      {t("Get discount!", { discount: t(this.getCurrency()) + parseFloat(this.props.payment.discount) * parseFloat(this.props.basketResult.length) })}
                    </p>

                      {!this.state.discount ? <button className="btn btn-apply" onClick={this.applyDiscount}>{this.props.t("Apply")}</button> :null}
                      {this.state.discount ? <p className="applied-text">{this.props.t("Discount applied")}</p> :null}
                    </div>
                                    :null}

                    { this.props.couponDiscountApplied ? 
                      <div className="applied coupon-cont d-flex justify-content-between align-items-center">
                          <p className="coupon-code-txt truncate">{this.props.couponCode}</p> 
                          <div className="flex-shrink-0 coupon-code-content-right">
                            <div><p className="applied-text">{this.props.t("DiscountPercent",{couponDiscountPercent :parseFloat( this.props.couponDiscountPercent ).toFixed(0)})}</p></div>
                            <div className="coupon-code-cancel"><button className="btn btn-coupon-cancel" onClick={this.handleCouponCancel} >{this.props.t("Cancel")}</button></div>
                          </div>
                      </div>
                    :null}


                </div>


              <div className="sum-container">
                <div className="plan-days d-flex align-items-center">
                  <img src={calendar} />
                  <p>
                    {this.props.basketResult.length === 1 ?
                      t("PlanPickedForDaySingle", { basketSource: parseFloat(this.props.basketSource.length), planDays: parseInt(this.props.planDays.length) })
                      :
                      t("PlanPickedForDayMultiple", { basketSource: parseFloat(this.props.basketSource.length), planDays: parseInt(this.props.planDays.length) })
                    }
                  </p>
                </div>
                <div className="pay-items">
                  <div className="pay-item d-flex align-items-center justify-content-between">
                    <p>
                      {t("x trip plan", { currency: t(this.getCurrency()), price: this.props.payment.price, count: this.props.basketResult.length })}
                    </p>
                    <p>
                      {t("CurencyPrice", { currency: t(this.getCurrency()), price: parseFloat(this.props.payment.price) * parseFloat(this.props.basketResult.length) })}
                    </p>
                  </div>
                  <div className="pay-item d-flex align-items-center justify-content-between">
                    <p>{t("Service fee")}</p>
                    <p>{t("CurencyPrice", { currency: t(this.getCurrency()), price: 0 })}</p>
                  </div>
                  { this.state.discount || this.props.couponDiscountApplied ? 
                  <div className="pay-item d-flex align-items-center justify-content-between">
                    <p>{this.props.t("Discount")}</p>
                    <p className="pay-green">-{t(this.getCurrency())}{this.calcDiscountPrice()}</p>
                  </div>
                  :null}
                  <div className="pay-total d-flex align-items-center justify-content-between">
                    <p className="total">{t("TotalCurency", { currency: t(this.getCurrency())})} </p>
                    {this.state.discount || this.props.couponDiscountApplied ?
                      <div className="d-flex align-items-center">
                        <p className="pay-discount">{t(this.getCurrency())}{parseFloat(this.props.payment.price).toFixed(2)}</p>
                        <p className="pay-total">{t(this.getCurrency())}{this.calculateDiscountedPrice()}</p>
                      </div>
                      : null}

                    {!this.state.discount && !this.props.couponDiscountApplied ?
                      <div className="d-flex align-items-center">
                        <p className="pay-discount"></p>
                        <p className="pay-total">{t(this.getCurrency())}{this.calculateDiscountedPrice()}</p>
                      </div>
                      : null}

                  </div>
                </div>
                <div className="cancel-policy">
                  <p className="head">{t("100% satisfaction guaranteed.")}</p>
                  <p className="desc">{t("If you're not satisfied, get a full refund.")}
                    <a href={"https://heytripster.com/distance-sales-agreement/?utm=" + this.props.name + "&email=" + this.props.email} target="_blank">{t("More Details")}</a>
                  </p>
                </div>
              </div>
              {!this.isFreeBasket() ?
                <button className="btn-d confirm-btn-desktop" form='my-form' disabled={this.props.submitDisable} type="submit" onClick={() => this.setState({buttonClick: true})}  >{t("Confirm and Pay")}</button>
                : <button className="btn-d confirm-btn-desktop" type="button" onClick={this.props.handleFreeBasket}  >{t("Confirm and Pay")}</button>
              }
              {!this.isFreeBasket() ?
                <div className="terms-container-mobile">
                  <p className="terms" dangerouslySetInnerHTML={{ __html: t("TermsText", { name: this.props.name, email: this.props.email }) }} />
                </div>
                : null}


            </div>
          </div>
        </div>
        <div className="mobile-confirm-container">
          <div className="mobile-confirm-wrap d-flex justify-content-between align-items-center">
            <div className="flex-shrink-0 flex-grow-0 back-chevron " onClick={this.props.onBack}>

         </div>
         <div className="flex-shrink-0 flex-grow-1 total-mob">
           <div className="total-txt">{t("Total")}</div>
           <div className="total-num">
               {
                 this.state.discount || this.props.couponDiscountApplied?
                  <p className="">{t(this.getCurrency())}{this.calculateDiscountedPrice()}</p>
                 :<p className="">{t(this.getCurrency())}{parseFloat(this.props.payment.price).toFixed(2)}</p>
              }
           </div>
         </div>
         <div className="flex-shrink-0 flex-grow-0">
          { !this.isFreeBasket() ?
           <button className={this.state.formValid ? "form-valid btnx confirm-btn-mobile":"btnx confirm-btn-mobile"} disabled={this.props.submitDisable}  form='my-form' onClick={() => this.setState({buttonClick: true})} type="submit"  >{t("Confirm and Pay")}</button>
           :<button className="form-valid btnx confirm-btn-mobile" type="button" onClick={this.props.handleFreeBasket}  >{t("Confirm and Pay")}</button>
          }
         </div>
      </div>
    </div>
  </div>
)};
}
Checkout.propTypes = {};

Checkout.defaultProps = {
  basketSource: [],
  basketResult: []
};

export default translate("translations")(Checkout);
