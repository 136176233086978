import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { I18nextProvider } from "react-i18next";

import userReducer from './store/reducers/user';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import axios from 'axios';
import i18n from "./i18n";

import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage'//local
import storageSession from 'redux-persist/lib/storage/session'
import { PersistGate } from 'redux-persist/integration/react'

//live a giderken buda değişecek... 

//const stripeKey = "pk_live_51Ho3IxJPD2yyfWSCYs7XofdhjNSMFaglKR7yXzJYjjPNmvb7WbYthSSBSNqT8etEo4NyhCNyqK5alz07Y9B77qBL00pRnEmeTt";
axios.defaults.baseURL = 'https://apitest.heytripster.com/';

export const AIRBNB_FONT_FULL_PATH = "http://tripster.mmdlabs.net/font/AirbnbCerealBook.ttf";

const theme = createMuiTheme({
   palette: {
      primary: {
         main: "#1b2d41" /*1eab31*/
                },
      secondary: {
         main: "#ccc" 
                 }
            }
});

const rootReducer = combineReducers({
    usr: userReducer
});

const persistConfig = {
  key: 'root',
  storage:storageSession
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

//const store = createStore(rootReducer);

let store = createStore(persistedReducer);
let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
        <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
        </PersistGate>
      </I18nextProvider>
  </Provider>
 ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();