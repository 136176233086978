import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './EventDetail.scss';
import { translate } from 'react-i18next';

import EventPreview from './components/EventPreview/EventPreview';
import EventCheckList from './components/EventCheckList/EventCheckList';
import EventSocial from './components/EventSocial/EventSocial';
import EventAbout from './components/EventAbout/EventAbout';
import EventLocation from './components/EventLocation/EventLocation';
import EventKeyInfo from './components/EventKeyInfo/EventKeyInfo';

import EventDesigner from './components/EventDesigner/EventDesigner';
import EventActions from './components/EventActions/EventActions';
import EventPlan from './components/EventPlan/EventPlan';
import {firstName,calcPrice} from "./../../assets/Helpers.js";
import { Translation } from 'react-i18next';
import PlanMapBox from './../PlanDetail/components/PlanMapBox/PlanMapBox'
import AltEventDetail from './../../containers/AltEventDetail/AltEventDetail';
import close from './../../assets/icons/close.svg';
import Slider from "react-slick";
import medialink from './../../assets/icons/media-link.svg';

import prevImg from './../../assets/icons/slider-left.svg';
import nextImg from './../../assets/icons/slider-right.svg';
const bonusSettings = {
  dots: false,
  arrows:false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
          { breakpoint: 720,settings: { slidesToShow: 1, slidesToScroll: 1, arrows: false, dots: true }}
        ]
};

class EventDetail extends Component {

    state = {
        selectedEventIndex: this.props.eventIndex,
        selectedEventData:null,
        events:this.props.events,
        altEvent:null,
        altTitle:"",
        altType:"",
        scrool:false,
    }

    handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        if(this.state.scrool)
        {
          setTimeout(
            () => this.removeAltEvent(""),
            5000
          );
        }
        console.log("scroll down");
      }
    }
    next = () => {
      this.slider.slickNext();
    }
    previous = () => {
      this.slider.slickPrev();
    }
    eventSelectedHandler = (id,index) => {
       console.log("eventSelectedHandler");
         if(this.props.isFullAccess || this.props.solted){
          this.setState({
            selectedEventData: this.state.events.filter(item => item.id == id)[0], 
            selectedEventIndex: index
          },this.props.onSelectedEventChanged());
        }
    }

    componentDidMount () {
         //this.eventSelectedHandler(this.state.events[0].id,0);

         console.log("componentDidMount",this.props.eventIndex);

         this.state.events.filter(item => !item.cardId).sort(((a, b) => a.startTime < b.startTime ? -1 : 1)).map((event, i) => {
          if(event.starEvent)
          {
            this.setState({
              selectedEventData: this.state.events.filter(item => item.starEvent == true)[0], 
              selectedEventIndex: i
            });
          }
        }
      )
    }

    handleAltEventFullAccess(){
        this.removeAltEvent();
        this.props.onFullAccess()
    }

    setAltEvent(ev,title,type){
       console.log("setAltEvent",ev);
      //  document.body.classList.add('modal-open');
      setTimeout(
        () => this.setState({
          scrool: true,
        }),
        200
      );
       this.setState({
                       altEvent: ev,
                       altTitle:title,
                       altType:type
                     },this.props.onAltEventToggle(true));
    }

    removeAltEvent(ev){
      // document.body.classList.remove('modal-open');
      setTimeout(
        () => this.setState({
          scrool: false,
        }),
        200
      );
       this.setState({
                       altEvent: null
                     },this.props.onAltEventToggle(false));
    }

    componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
        console.log("componentWillReceiveProps",nextProps);

          if(this.props.isFullAccess && nextProps.eventIndex){

                     var id = nextProps.eventIndex;
                    console.log("componentWillReceiveProps",id);
                     this.setState({
                       selectedEventData: this.state.events.filter(item => item.id == id[0])[0], 
                       selectedEventIndex:  id[1]//,
                       //altEvent:this.props.plan.cards[0]
                     });

                   }

    }

     render () {
         const { t, plan } = this.props;
      return(
      <div className="EventDetail">
        <div className="container d-flex flex-wrap justify-content-between align-items-start" >

        {!this.props.print &&
          this.state.selectedEventData ?
        	<div className="main">
        		<EventPreview {...this.state.selectedEventData} events={this.props.events} solted={this.props.solted} eventIndex={this.state.selectedEventIndex} designerName={firstName(this.props.designer.fullName)} plan={this.props.plan}/>
            <EventAbout {...this.state.selectedEventData} solted={this.props.solted} />
             <p className="new-desc">{this.state.selectedEventData.shortDescription}</p>

        		 {this.state.selectedEventData.lat && this.state.selectedEventData.lng ? <EventLocation {...this.state.selectedEventData} mapOpen={() => this.props.onMapOpen()} /> :null}
        		<EventKeyInfo {...this.state.selectedEventData} events={this.props.events}/>
            {this.state.selectedEventData.media?
              <div className="media-link">
                <a href={this.state.selectedEventData.media} target="_blank" rel="nofollow noreferrer noopener">{this.props.t("Media Link related to the Event")} <img src={medialink}/></a>
              </div>
            :null
            }

            {this.props.t("VisibleAreaAlternativeEventContainer") !== "VisibleAreaAlternativeEventContainer" && this.props.t("VisibleAreaAlternativeEventContainer") === "True" &&  this.state.selectedEventData 
              && (this.props.events.filter(item => item.cardId == this.state.selectedEventData.id).length + this.state.selectedEventData.spots.length) > 0? 
            <div className="bonus-alt-container slider-container">
              {this.state.selectedEventData.spots != undefined &&  this.state.selectedEventData.spots != null && this.state.selectedEventData.spots.length > 1 &&
               <div className="d-flex flex-wrap justify-content-between align-items-center ">
                <h2 className="section-title">{this.props.t("")}</h2>
                <div className="d-flex flex-nowrap justify-content-start align-items-center explore-header">
                  {/* <p className="show-all-btn">Show All Plans (15)</p>*/}
                  <button className="sliderBtn" onClick={this.previous}><img className="" src={prevImg} /></button>
                  <button className="sliderBtn" onClick={this.next}><img className="" src={nextImg} /></button>
                </div>
              </div>
              }
               <Slider ref={c => (this.slider = c)} {...bonusSettings}>
 

                         {
                            this.props.events.filter(item => item.cardId == this.state.selectedEventData.id).map((eventData, i) => 
                              <div className="ba-wrap" key={i}>
                                  <div className="ba-event">
                                    <div className="type-txt">{t("Alternative Event")}</div>
                                       {eventData.images && eventData.images.split(";").filter(x => x).length > 0 ?
                                            <div className="ev-img"  style={{ backgroundImage:  "url(" + eventData.images.split(";").filter(x => x)[0] + ")" }} alt={eventData.locationName}></div> 
                                                 : <div className="ev-img"  style={{ backgroundImage:  "url(https://api.heytripster.com/resources/images/376/0576dadc-6315-4f91-bbac-0808ceaceab2.jpg)" }} alt={eventData.locationName}></div>
                                             }
                                      <div className="ev-cnt">
                                        <h5 className="bonus-title">{eventData.locationName}</h5>
                                        <p className="bonus-txt">{eventData.shortDescription.length > 270 ?  eventData.shortDescription.substring(0,270) + "..." : eventData.shortDescription}</p>
                                        <p className="bonus-tag">{t(eventData.eventTypeText)}</p>
                                        <button class="btn-w" onClick={()=>this.setAltEvent(eventData,t("Alternative Event"),"alternative")}>{this.props.t("Show Details")}</button>
                                      </div>
                                    </div>
                                </div>
                              )
                          }
                                   {
                    this.state.selectedEventData.spots.map((spot, i) =>
                      <div className="ba-wrap" key={i}>
                          <div className="ba-event">
                          <div className="type-txt bns">{this.props.t("Bonus Location", {count : i+1})}</div>
                              {spot.images && spot.images.split(";").filter(x => x).length > 0 ?
                                            <div className="ev-img"  style={{ backgroundImage:  "url(" + spot.images.split(";").filter(x => x)[0] + ")" }} alt={spot.locationName}></div> 
                                                 : <div className="ev-img"  style={{ backgroundImage:  "url(https://api.heytripster.com/resources/images/376/0576dadc-6315-4f91-bbac-0808ceaceab2.jpg)" }} alt={spot.locationName}></div>
                                             }
                            <div className="ev-cnt">
                              <h5 className="bonus-title">{spot.locationName}</h5>
                              <p className="bonus-txt">{spot.shortDescription.length > 270 ?  spot.shortDescription.substring(0,270) + "..." : spot.shortDescription}</p>
                              <p className="bonus-tag">{t(spot.eventTypeText)}</p>
                               <button class="btn-w" onClick={()=>this.setAltEvent(spot, this.props.t("Bonus Location", {count : i+1}),"bonus")} >{this.props.t("Show Details")}</button>

                            </div>
                          </div>
                        </div>
                    )}
                </Slider>
           
                              
            </div>:null}
            {t("VisibleAreaEventCheckList") !== "VisibleAreaEventCheckList" && t("VisibleAreaEventCheckList") === "True" && 
              <EventCheckList {...this.state.selectedEventData} />
            }
                        
                        {/* {this.state.selectedEventData && this.state.selectedEventData.medias && this.state.selectedEventData.medias.length > 0 ? <EventSocial medias={this.state.selectedEventData.medias}/> :null} */}


        	</div>
          : null 
        }
        {this.props.print &&
          this.state.selectedEventData ?
          this.state.events.filter(x => x.cardId === null).map((selectedEventData, i) => {
            return (
            <>
              <div className="main">
        		<EventPreview {...selectedEventData} events={this.props.events} solted={this.props.solted} eventIndex={i} designerName={firstName(this.props.designer.fullName)} plan={this.props.plan}/>
            <EventAbout {...selectedEventData} solted={this.props.solted} />
             <p className="new-desc">{selectedEventData.shortDescription}</p>

        		 {selectedEventData.lat && selectedEventData.lng ? <EventLocation {...selectedEventData} mapOpen={() => this.props.onMapOpen()} /> :null}
        		<EventKeyInfo {...selectedEventData} events={this.props.events}/>
            <p className="new-desc"></p>
            <EventCheckList {...selectedEventData} />
            {selectedEventData.media?
              <div className="media-link">
                <a href={selectedEventData.media} target="_blank" rel="nofollow noreferrer noopener">{this.props.t("Media Link related to the Event")} <img src={medialink}/></a>
              </div>
            :null
            }
            </div>
                        {/* {this.state.selectedEventData && this.state.selectedEventData.medias && this.state.selectedEventData.medias.length > 0 ? <EventSocial medias={this.state.selectedEventData.medias}/> :null} */}

                        {selectedEventData 
              && (this.props.events.filter(item => item.cardId == selectedEventData.id).length + selectedEventData.spots.length) > 0? 
            <>
              {
                this.props.events.filter(item => item.cardId == selectedEventData.id).map((eventData, i) => 
                <div className="main">
                  <EventPreview {...eventData} events={this.props.events} solted={this.props.solted} eventIndex={i} designerName={firstName(this.props.designer.fullName)} plan={this.props.plan}/>
                  <EventAbout {...eventData} solted={this.props.solted} />
                  <p className="new-desc">{eventData.shortDescription}</p>
      
                  {eventData.lat && eventData.lng ? <EventLocation {...eventData} mapOpen={() => this.props.onMapOpen()} /> :null}
                  <EventKeyInfo {...eventData} events={this.props.events}/>
                  <p className="new-desc"></p>
                  <EventCheckList {...eventData} />
                  {eventData.media?
                    <div className="media-link">
                      <a href={eventData.media} target="_blank" rel="nofollow noreferrer noopener">{this.props.t("Media Link related to the Event")} <img src={medialink}/></a>
                    </div>
                  :null
                  }
                </div>
                  )
              }
              {
                  selectedEventData.spots.map((spot, i) =>
                    <div className="main"  key={i}>
                      <EventPreview {...spot} spot={true} events={this.props.events} solted={this.props.solted} eventIndex={i} designerName={firstName(this.props.designer.fullName)} plan={this.props.plan}/>
                      <EventAbout {...spot} solted={this.props.solted} />
                      <p className="new-desc">{spot.shortDescription}</p>
          
                      {spot.lat && spot.lng ? <EventLocation {...spot} mapOpen={() => this.props.onMapOpen()} /> :null}
                      <EventKeyInfo {...spot} events={this.props.events}/>
                    </div>
              )}
            </>:null}
        	
            </>);
          })
          : null 
        }
        {!this.props.print &&
        	<div className="sidebar">
                <div className="c-wrap"  >
                  {
                    this.props.t("VisibleAreaTripDesignerInfo2") !== "VisibleAreaTripDesignerInfo2" && this.props.t("VisibleAreaTripDesignerInfo2") === "True" &&
    	    	        <EventDesigner {...this.props.designer}/>
                  }
                {this.props.t("VisibleAreaSatisfactionGarantied") !== "VisibleAreaSatisfactionGarantied" && this.props.t("VisibleAreaSatisfactionGarantied") === "True" && !this.props.solted &&
    	    	    <EventActions
                    fullAccess={() =>  this.props.onFullAccess()} 
                    browse={() =>  this.props.onBrowse()}
                    currency={this.props.plan.priceCurrency}
                    price={this.props.plan.discountedPrice.toFixed(2)}
                    status={this.props.plan.status}
                    />}
                </div>
                  <div id="sticky-trigger" ></div>

                  <div className="event-plan-container" id="sticky-sidebar" >
                    <div className="event-plan-wrap"  >
                      {this.props.t("VisibleAreaPlanCardList") !== "VisibleAreaPlanCardList" && this.props.t("VisibleAreaPlanCardList") === "True" &&
                        <>
                        <h2 className="title">{t("name's Plan", {name : firstName(this.props.designer.fullName)})}</h2>
                        <h4 className="new-plan-title"> {this.props.plan.themeOfTheDay}</h4>
                        <div className="plan-items">

                        {this.state.events.filter(item => !item.cardId).sort(((a, b) => a.startTime < b.startTime ? -1 : 1)).map((evitem, index) => {
                                return <EventPlan key={"ei-"+index} indexs={index} event={evitem} 
                                selected={this.state.selectedEventData && this.state.selectedEventData.id == evitem.id ? true:false} 
                                clicked={() => this.props.onSelectEventAndScroll(new Array(evitem.id, index))}
                                isFullAccess={this.props.isFullAccess}
                                  />
                              })}
                        </div>

                          { this.props.plan && this.props.plan.cards && this.props.plan.cards.filter(item=> item.lat && item.lng ).length > 0 ?  
                              <PlanMapBox solted={this.props.solted}  mapOpen2={() => this.props.onMapOpen2()}  mapOpen={() => this.props.onMapOpen()} locations={this.props.plan.cards.filter(item=> item.lat && item.lng ).reduce((locs, item) =>locs.concat([{lat:item.lat,lng:item.lng}]),[])} /> 
                              : null
                          }
                        </>
                      }
                        
                        {this.props.t("VisibleAreaGetFullAccess") !== "VisibleAreaGetFullAccess" && this.props.t("VisibleAreaGetFullAccess") === "True" && !this.props.solted &&
                        <div className="full-access">
                        <p>{t("Fully access to this plan for (one time).  %100 satisfaction guaranteed!",{priceCurrency:t(this.props.plan.priceCurrency), discountedPrice: this.props.plan.discountedPrice.toFixed(2)})}</p>
                        <button className={`btn btn-w ${this.props.status !== 10 ? "":"dis"}`} onClick={() => this.props.onFullAccess()} disabled={this.props.plan.status !== 10}  >{t(`Get Full Access_${this.props.plan.status}`)}</button>
                        </div>
                        }
                     </div>
                  </div>
                  {this.props.t("VisibleAreaTripDesignerWithYou") !== "VisibleAreaTripDesignerWithYou" && this.props.t("VisibleAreaTripDesignerWithYou") === "True" && 
                    <div className="join-event">
                      <p dangerouslySetInnerHTML={{__html: t("tripDesigner-join-event", {firstname : firstName(this.props.designer.fullName)})}} />
                    </div>
                  }
                
        	</div>
        }
        </div>


                 {this.state.altEvent ?
                  <div className="bonus-view">
                  <div className="bonus-view-backdrop" onClick={()=>this.removeAltEvent()} ></div>
                    <div className="bonus-shelf" onScroll={this.handleScroll}>

                        <div className="shelf-title">
                          <h5>{this.state.altTitle}</h5>
                          <button className="btn" onClick={()=>this.removeAltEvent()} ><img src={close}/></button>
                        </div>
                         <AltEventDetail
                                     event={this.state.altEvent}
                                     solted={this.props.solted}
                                     /* onMapOpen={() => this.handleClickOpen()}*/                                        
                                        eventIndex={0}
                                       
                                        isFullAccess={this.props.isFullAccess}
                                        onFullAccess={() => this.handleAltEventFullAccess()}
                                        /*  onBrowse={() => this.handleOpenDatePopup(false)}*/
                                       
                                        events={this.props.events}
                                        designer={this.props.designer}
                                        plan={this.props.plan}
                                        etype={this.state.altType}
                                    />

                    </div>
                    </div>
                :null}
      </div>
    );}
}

export default translate("translations")(EventDetail);
